/* footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    color: #333;
    padding: 15px 0;
    font-size: 14px;
    z-index: 20;
    border-top: 1px solid #e7e7e7; 
    box-shadow: 0 -10px 4px -6px rgba(35, 194, 14, 0.541);
  
  }
  
  footer p {
    margin: 0;
  }
  
  footer a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  } */


  footer {
   position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    color: #333;
    padding: 20px 0;
  
    font-size: 14px;
    z-index: 20;
    border-top: 1px solid #e7e7e7;
    box-shadow: 0 -10px 4px -6px rgba(35, 194, 14, 0.541);
   
  }
  
  .footer-content {
    margin: 0;
  }

    
  footer a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  } 
  
  @media screen and (max-width: 767px) {
    .footer-content {
      font-size: 14px;
    }
  }
  
  
  