

.subhero h2{
   text-align: justify;
   margin-top: 20px;
}

.subhero p{
    text-align: justify;
    
}