.manuscript{
    background-color: #0d6efd;
    width: 60%;
    padding: 10px 0;
    text-align: center;
    margin: auto;
}

.manuscript a{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
}

.cal-info-head h1{
    font-size: 30px;
    padding: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    background: #fd7e14;
    color: #fff;
    border: 1px solid #000;
}